import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { useAuth } from 'core/auth/browser';
import { Base, Card, ChipV2, Tooltip } from 'lib/cortiUI';
import { useInteraction } from '../../../context';
import { AgentActions } from '../AgentActions';
export const NoAgentInput = () => {
    const { t } = useTranslation('caseView', { keyPrefix: 'acknowledgementStatus.noAgentInput' });
    const { interaction } = useInteraction();
    const { currentUserID } = useAuth();
    if (!interaction)
        return null;
    return (React.createElement(Card, { display: "flex", flexDirection: "column", gap: 5, p: 6 },
        React.createElement(Base, { display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" },
            React.createElement(ChipV2, { variant: "outlined", label: t('chipLabel', 'Agent Input') }),
            React.createElement(Tooltip, { title: t('tooltipLabel', 'Allows the agent to provide feedback for this interaction. Only available to the interaction owner.') },
                React.createElement(InfoOutlinedIcon, { fontSize: "small" }))),
        currentUserID === interaction.interaction_owner && React.createElement(AgentActions, null)));
};
