import React from 'react';
import { subDays } from '@corti/date';
import { rbacService } from 'browser/services/init';
import { useAuth } from 'core/auth/browser';
import { useTemplate } from '../template';
import { useParametersCache } from './cacheHook';
const initialState = {
    teams: [],
    agents: [],
    dateRange: {
        startDate: new Date(),
        endDate: new Date(),
    },
    complianceStatus: undefined,
    filterRequestParams: {},
    acknowledgementStatus: [],
    updateParameters: () => undefined,
};
export const ParametersContext = React.createContext(initialState);
export const ParametersProvider = ({ children }) => {
    const { currentUser } = useAuth();
    const { template } = useTemplate();
    const cacheHandlers = useParametersCache();
    const DEFAULT_PERIOD = 7;
    const hasPermissionToReadAllTeamsAndAgents = rbacService.hasPermission('cases:other_users:read') &&
        rbacService.missionControlOtherAssessmentsRead();
    const [parameters, setParameters] = React.useState(Object.assign(Object.assign(Object.assign({}, initialState), { agents: hasPermissionToReadAllTeamsAndAgents
            ? initialState.agents
            : [{ id: currentUser.id, name: currentUser.name }], dateRange: {
            startDate: subDays(initialState.dateRange.endDate, DEFAULT_PERIOD),
            endDate: initialState.dateRange.endDate,
        } }), cacheHandlers.getPersistedParameters()));
    const updateParameters = (newParameters) => {
        setParameters((prevParameters) => (Object.assign(Object.assign({}, prevParameters), newParameters)));
        cacheHandlers.persistParameters(Object.assign(Object.assign({}, parameters), newParameters));
    };
    const getUserIds = (users, teams) => {
        return users.length
            ? users.map((user) => user.id)
            : teams.flatMap((team) => team.members.map((member) => member.user.id));
    };
    const getScoreRangeFromComplianceStatus = React.useCallback((status) => {
        if (!template)
            return;
        switch (status) {
            case 'COMPLIANT':
                return { min_score: template.threshold - 1, max_score: 100 };
            case 'PARTIALLY_COMPLIANT':
                return { min_score: template.partial_threshold - 1, max_score: template.threshold - 1 };
            case 'NON_COMPLIANT':
                return {
                    min_score: 0,
                    max_score: template.has_partial_threshold
                        ? template.partial_threshold - 1
                        : template.threshold - 1,
                };
        }
    }, [template]);
    const scoreRange = React.useMemo(() => getScoreRangeFromComplianceStatus(parameters.complianceStatus), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [parameters.complianceStatus, template === null || template === void 0 ? void 0 : template.id]);
    const filterRequestParams = Object.assign({ user_ids: getUserIds(parameters.agents, parameters.teams), call_started_after: parameters.dateRange.startDate.toISOString(), call_ended_before: parameters.dateRange.endDate.toISOString(), status: ['DONE', 'ACKNOWLEDGED', 'FLAGGED'], acknowledgement_status: parameters.acknowledgementStatus, latest_only: true }, (scoreRange !== null && scoreRange !== void 0 ? scoreRange : {}));
    const value = React.useMemo(() => (Object.assign(Object.assign({}, parameters), { updateParameters, filterRequestParams })), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [parameters, scoreRange]);
    return React.createElement(ParametersContext.Provider, { value: value }, children);
};
export const useParameters = () => {
    const context = React.useContext(ParametersContext);
    if (!context) {
        throw new Error('useParameters must be used within a ParametersProvider');
    }
    return context;
};
