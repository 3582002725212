import React from 'react';
import { getQuestions } from '@corti/mission-control-api';
import { Base } from 'lib/cortiUI';
import { Question } from './Question';
import { QuestionsLoader } from './QuestionsLoader';
export const Questions = ({ template, reviewID }) => {
    const [questions, setQuestions] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        void fetchQuestions(template.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template.id]);
    async function fetchQuestions(templateID) {
        setIsLoading(true);
        try {
            const res = await getQuestions(templateID);
            setQuestions(res);
        }
        catch (e) {
            console.error('Could not fetch a review questions', e);
        }
        finally {
            setIsLoading(false);
        }
    }
    if (isLoading) {
        return React.createElement(QuestionsLoader, null);
    }
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 4 }, questions.map((question) => (React.createElement(Question, Object.assign({}, question, { reviewID: reviewID, scoreEnabled: template.scoring_enabled, key: question.id }))))));
};
