import React from 'react';
import { formatDateTime } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { getTemplatesList } from '@corti/mission-control-api';
import { theme } from '@corti/theme';
import { repositories } from 'core/repositories';
import { Base, DataGrid as CortiUIDataGrid } from 'lib/cortiUI';
import { DataGridLoader } from './DataGridLoader';
import { NoDataGrid } from './NoDataGrid';
import { getColumns } from './columns';
export const DataGrid = () => {
    var _a;
    const { t } = useTranslation('templateList', { keyPrefix: 'dataGrid' });
    const [templates, setTemplates] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        void fetchTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    async function fetchTemplates() {
        setIsLoading(true);
        try {
            const res = await getTemplatesList({ status: ['ACTIVE'] });
            setTemplates(res);
        }
        catch (e) {
            console.error('Could not fetch templates', e);
        }
        finally {
            setIsLoading(false);
        }
    }
    function getRows() {
        return templates === null || templates === void 0 ? void 0 : templates.map((it) => {
            var _a, _b;
            return {
                id: it.id,
                title: it.name,
                createdBy: (_b = (_a = repositories.users.getUser(it.created_by)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '',
                createdAt: formatDateTime(new Date(it.created_at)),
                actionToolbar: it,
                status: it.published
                    ? {
                        color: theme.palette.success.main,
                        text: t('status.published', 'Published'),
                    }
                    : { text: t('status.draft', 'Draft') },
            };
        });
    }
    function removeRow(id) {
        setTemplates(templates.filter((it) => it.id !== id));
    }
    if (isLoading) {
        return React.createElement(DataGridLoader, null);
    }
    return (React.createElement(CortiUIDataGrid, { autoHeight: true, disableColumnFilter: true, disableColumnMenu: true, disableSelectionOnClick: true, columns: getColumns({ removeRow }), rows: (_a = getRows()) !== null && _a !== void 0 ? _a : [], loading: isLoading, components: {
            LoadingOverlay: !templates.length ? DataGridLoader : Base,
            NoRowsOverlay: !templates.length && isLoading ? Base : NoDataGrid,
        }, sx: {
            '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
            },
        } }));
};
