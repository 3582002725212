import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Base, Container, Page } from 'lib/cortiUI';
import { Configuration } from './Configuration';
import { Questions } from './Questions';
import { TemplateProvider } from './context';
export function Builder() {
    const { params: { templateID }, } = useRouteMatch();
    if (!templateID) {
        throw Error('`templateID` must be provided as Route parameters to use `Builder` component');
    }
    return (React.createElement(Page, null,
        React.createElement(Container, { sx: { height: '100%' } },
            React.createElement(Base, { height: "100%", display: "flex", flexDirection: "column", gap: 8 },
                React.createElement(TemplateProvider, { templateID: templateID },
                    React.createElement(Configuration, null),
                    React.createElement(Questions, null))))));
}
