import DownloadDoneRoundedIcon from '@mui/icons-material/DownloadDoneRounded';
import ShortcutOutlinedIcon from '@mui/icons-material/ShortcutOutlined';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { acknowledgeInteraction, requestInteractionReview } from '@corti/mission-control-api';
import { coreStore } from 'browser/stores';
import { Base, Button, TextField, Typography } from 'lib/cortiUI';
import { useInteraction } from '../../context';
export const AgentActions = () => {
    const { t } = useTranslation('caseView', { keyPrefix: 'acknowledgementStatus.agentActions' });
    const { interaction, refetchInteraction } = useInteraction();
    const [showInput, setShowInput] = React.useState(false);
    const [inputValue, setInputValue] = React.useState('');
    const [isActionLoading, setIsActionLoading] = React.useState(false);
    if (!interaction)
        return null;
    async function handleAcknowledge() {
        setIsActionLoading(true);
        try {
            if (!interaction)
                return null;
            await acknowledgeInteraction(interaction.id);
            void refetchInteraction();
            coreStore.notifications.showNotification({
                message: t('acknowledgeSuccess', 'Interaction acknowledged'),
            });
        }
        catch (error) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('acknowledgeError', 'Failed to acknowledge'),
            });
        }
        finally {
            setIsActionLoading(false);
        }
    }
    async function handleReviewRequest() {
        setIsActionLoading(true);
        try {
            if (!interaction)
                return null;
            await requestInteractionReview(interaction.id, inputValue);
            void refetchInteraction();
            coreStore.notifications.showNotification({
                message: t('requestReviewSuccess', 'Review Requested'),
            });
        }
        catch (error) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('requestReviewError', 'Failed to request review'),
            });
        }
        finally {
            setIsActionLoading(false);
        }
    }
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5 },
        React.createElement(Typography, { variant: "body2", color: "default" }, t('furtherAttentionNeeded', 'Is further attention needed for this interaction? ')),
        React.createElement(Base, { display: "flex", flexDirection: "row", gap: 5 },
            React.createElement(Button, { size: "small", color: "primary", disabled: isActionLoading, onClick: handleAcknowledge },
                React.createElement(DownloadDoneRoundedIcon, null),
                " ",
                t('acknowledgeIt', 'No, acknowledge it')),
            React.createElement(Button, { size: "small", variant: "outlined", onClick: () => setShowInput(true) },
                React.createElement(ShortcutOutlinedIcon, null),
                t('requestReview', 'Request a review'))),
        showInput && (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5 },
            React.createElement(TextField, { value: inputValue, onChange: ({ target }) => setInputValue(target.value), multiline: true, rows: 3, fullWidth: true, placeholder: t('reasonInputPlaceholder', 'Write a few lines elaborating on the reason for your review request') }),
            React.createElement(Base, { display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" },
                React.createElement(Typography, { variant: "caption" }, t('notifiedAboutReview', 'You will be notified when a review is available.')),
                React.createElement(Button, { size: "small", variant: "text", disabled: isActionLoading || !inputValue, onClick: handleReviewRequest }, t('sendRequest', 'Send request')))))));
};
