import { intl } from '@corti/i18n';
export function getReviewComplianceStatusPaletteColor(status, palette) {
    switch (status) {
        case 'COMPLIANT':
            return palette.success.light;
        case 'NON_COMPLIANT':
            return palette.error.light;
        case 'PARTIALLY_COMPLIANT':
            return palette.warning.light;
        case 'NONE':
        default:
            return;
    }
}
export function getReviewComplianceStatusData(status) {
    switch (status) {
        case 'COMPLIANT':
            return {
                color: 'success',
                label: intl.t('missionControlApp:performanceStatus.compliant', 'Compliant'),
            };
        case 'NON_COMPLIANT':
            return {
                color: 'error',
                label: intl.t('missionControlApp:performanceStatus.nonCompliant', 'Non Compliant'),
            };
        case 'PARTIALLY_COMPLIANT':
            return {
                color: 'warning',
                label: intl.t('missionControlApp:performanceStatus.partiallyCompliant', 'Partially Compliant'),
            };
        case 'NONE':
        default:
            return {
                label: '-',
            };
    }
}
export function getComplianceStatusFromThresholds(score_percentage, template) {
    const { has_threshold, has_partial_threshold, threshold, partial_threshold } = getDefaultThresholds(template);
    if (has_threshold && score_percentage >= threshold) {
        return 'COMPLIANT';
    }
    if ((has_threshold && !has_partial_threshold && score_percentage < threshold) ||
        (has_partial_threshold && score_percentage < partial_threshold)) {
        return 'NON_COMPLIANT';
    }
    if (has_partial_threshold && score_percentage < threshold) {
        return 'PARTIALLY_COMPLIANT';
    }
    return 'NONE';
}
export function getDefaultThresholds(template) {
    var _a, _b, _c, _d;
    return {
        has_threshold: (_a = template === null || template === void 0 ? void 0 : template.has_threshold) !== null && _a !== void 0 ? _a : false,
        has_partial_threshold: (_b = template === null || template === void 0 ? void 0 : template.has_partial_threshold) !== null && _b !== void 0 ? _b : false,
        threshold: (_c = template === null || template === void 0 ? void 0 : template.threshold) !== null && _c !== void 0 ? _c : 0,
        partial_threshold: (_d = template === null || template === void 0 ? void 0 : template.partial_threshold) !== null && _d !== void 0 ? _d : 0,
    };
}
