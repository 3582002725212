import React from 'react';
import { useTranslation } from '@corti/i18n';
import { enableTemplateScoring, setThresholds, } from '@corti/mission-control-api';
import { coreStore } from 'browser/stores';
import { Base, SettingsCheckInput, Switch } from 'lib/cortiUI';
import { useTemplate } from '../context';
import { ThresholdsTrigger } from './ThresholdsTrigger';
export const Scoring = ({ thresholdsData, updateThresholds }) => {
    const { t } = useTranslation('template', { keyPrefix: 'builder.configuration' });
    const { isScoringEnabled, setIsScoringEnabled, templateID } = useTemplate();
    const [isLoading, setIsLoading] = React.useState(false);
    async function toggleScoring(value) {
        try {
            setIsLoading(true);
            await enableTemplateScoring(templateID, { scoring_enabled: value });
            if (!value) {
                await setThresholds(templateID, { has_threshold: false });
                updateThresholds({
                    has_threshold: false,
                    threshold: 0,
                    has_partial_threshold: false,
                    partial_threshold: 0,
                });
            }
            setIsScoringEnabled(value);
            coreStore.notifications.showNotification({
                message: value
                    ? t('enableScoringSuccess', 'Scoring enabled')
                    : t('disableScoringSuccess', 'Scoring disabled'),
            });
        }
        catch (_a) {
            coreStore.notifications.showNotification({
                message: value
                    ? t('enableScoringError', 'Failed to enable scoring')
                    : t('disableScoringError', 'Failed to disable scoring'),
                type: 'error',
            });
        }
        finally {
            setIsLoading(false);
        }
    }
    return (React.createElement(Base, { display: "flex", gap: 6 },
        React.createElement(SettingsCheckInput, { label: t('scoringLabel', 'Scoring'), labelInfo: t('scoringInfo', 'Enabling Scoring allows you to assign scores to specific answer types and set Compliance threshold values for submitted Assessments that use this template.'), inputComponent: React.createElement(Switch, { checked: isScoringEnabled, onChange: (_, checked) => toggleScoring(checked) }), disabled: isLoading }),
        React.createElement(ThresholdsTrigger, { disabled: isLoading, initialThresholds: thresholdsData })));
};
