import { EditRounded, RemoveRounded } from '@mui/icons-material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { isEqual } from 'lodash';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { setThresholds } from '@corti/mission-control-api';
import { coreStore } from 'browser/stores';
import { Base, Button, IconButton, Modal, ModalContent, ModalHeader, Separator, Typography, } from 'lib/cortiUI';
import { useTemplate } from '../../context';
import { ActionButtons } from './ActionButtons';
import { ThresholdInput } from './ThresholdInput';
export const ThresholdsTrigger = ({ disabled, initialThresholds, }) => {
    const { t } = useTranslation('template', {
        keyPrefix: 'builder.configuration.evaluationCriteriaModal',
    });
    const VALUE_MIN = 0;
    const VALUE_MAX = 100;
    const DEFAULT_COMPLIANT_VALUE = 50;
    const { isScoringEnabled, templateID } = useTemplate();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const getDefaultThresholds = () => (Object.assign(Object.assign({}, initialThresholds), { threshold: initialThresholds.has_threshold
            ? initialThresholds.threshold
            : DEFAULT_COMPLIANT_VALUE }));
    const defaultThresholds = getDefaultThresholds();
    const [thresholdsData, setThresholdsData] = React.useState(Object.assign(Object.assign({}, defaultThresholds), { has_threshold: true }));
    const thresholdsRef = React.useRef(defaultThresholds);
    React.useEffect(() => {
        setThresholdsData(Object.assign(Object.assign({}, getDefaultThresholds()), { has_threshold: true }));
        thresholdsRef.current = getDefaultThresholds();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialThresholds]);
    const addThreshold = () => {
        const { partial_threshold } = thresholdsRef.current;
        setThresholdsData((c) => (Object.assign(Object.assign({}, c), { has_partial_threshold: true, partial_threshold: partial_threshold > 0 ? partial_threshold : Math.floor(c.threshold / 2) })));
    };
    const removeThreshold = () => {
        setThresholdsData((c) => (Object.assign(Object.assign({}, c), { has_partial_threshold: false })));
    };
    const handleCompliantChange = (e) => {
        const value = e.target.value ? +e.target.value.replace(/^0+/, '') : 0;
        if (value > VALUE_MAX)
            return;
        if (value < VALUE_MIN) {
            setThresholdsData((c) => (Object.assign(Object.assign({}, c), { has_threshold: true, threshold: c.has_partial_threshold ? VALUE_MIN + 2 : VALUE_MIN + 1 })));
            return;
        }
        setThresholdsData((c) => (Object.assign(Object.assign({}, c), { has_threshold: true, threshold: value })));
    };
    const handlePartiallyCompliantChange = (e) => {
        const value = e.target.value ? +e.target.value.replace(/^0+/, '') : 0;
        if (value > VALUE_MAX)
            return;
        setThresholdsData((c) => (Object.assign(Object.assign({}, c), { partial_threshold: value < VALUE_MIN ? VALUE_MIN + 1 : value })));
    };
    const handleReset = () => {
        setThresholdsData(thresholdsRef.current);
    };
    const validateThresholds = () => {
        const { threshold, partial_threshold, has_partial_threshold } = thresholdsData;
        if (threshold <= VALUE_MIN || threshold > VALUE_MAX) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: 'Compliant value must be between 1 and 100',
            });
            return true;
        }
        if (has_partial_threshold) {
            if (partial_threshold <= VALUE_MIN) {
                coreStore.notifications.showNotification({
                    type: 'error',
                    message: 'Partially Compliant value must be between 1 and 100',
                });
                return true;
            }
            if (threshold <= partial_threshold) {
                coreStore.notifications.showNotification({
                    type: 'error',
                    message: 'Compliant value must be greater than Partially Compliant value',
                });
                return true;
            }
        }
        return false;
    };
    const handleSave = async () => {
        const hasError = validateThresholds();
        if (hasError)
            return;
        try {
            setIsLoading(true);
            await setThresholds(templateID, thresholdsData);
            thresholdsRef.current = thresholdsData;
            setIsModalOpen(false);
            coreStore.notifications.showNotification({
                type: 'success',
                message: 'Thresholds updated successfully',
            });
        }
        catch (error) {
            console.error('Failed to update thresholds:', error);
            coreStore.notifications.showNotification({
                type: 'error',
                message: 'Failed to update thresholds',
            });
        }
        finally {
            setIsLoading(false);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "outlined", color: !thresholdsRef.current.has_threshold ? 'primary' : undefined, size: "small", disabled: disabled, onClick: () => setIsModalOpen(true), sx: { visibility: !isScoringEnabled ? 'hidden' : 'visible' }, startIcon: thresholdsRef.current.has_threshold ? React.createElement(EditRounded, { fontSize: "small" }) : null }, !thresholdsRef.current.has_threshold
            ? t('setComplianceCriteria', 'Set compliance criteria')
            : t('complianceCriteria', 'Compliance criteria')),
        React.createElement(Modal, { open: isModalOpen, onClose: () => setIsModalOpen(false), keepMounted: false },
            React.createElement(React.Fragment, null,
                React.createElement(ModalHeader, { title: t('title', 'Compliance Criteria') }),
                React.createElement(Typography, { variant: "body1", px: 7, mb: 7 }, t('description', 'Set the threshold values that will define the Compliance status of Assessments that use this template.')),
                React.createElement(Separator, { horizontal: true }),
                React.createElement(ModalContent, { py: 6, display: "flex", alignItems: "center", gap: 4 },
                    React.createElement(Base, { display: "grid", gap: 4, width: "100%" },
                        React.createElement(ThresholdInput, { label: t('compliant', 'Compliant'), value: thresholdsData.threshold, color: "success", onChange: handleCompliantChange }),
                        thresholdsData.has_partial_threshold ? (React.createElement(ThresholdInput, { label: t('partiallyCompliant', 'Partially Compliant'), value: thresholdsData.partial_threshold, color: "warning", onChange: handlePartiallyCompliantChange })) : (React.createElement(Button, { endIcon: React.createElement(AddRoundedIcon, null), onClick: addThreshold, variant: "contained", size: "large", color: "primary" }, t('partiallyCompliant', 'Partially Compliant'))),
                        React.createElement(ThresholdInput, { disabled: true, label: t('nonCompliant', 'Non Compliant'), value: thresholdsData.has_partial_threshold
                                ? thresholdsData.partial_threshold
                                : thresholdsData.threshold, color: "error" })),
                    thresholdsData.has_partial_threshold && (React.createElement(IconButton, { icon: React.createElement(RemoveRounded, { color: "action" }), onClick: removeThreshold }))),
                React.createElement(Separator, { horizontal: true }),
                React.createElement(ActionButtons, { onReset: handleReset, onSave: handleSave, isEqualToServer: isEqual(thresholdsRef.current, thresholdsData), isLoading: isLoading })))));
};
