import { isEqual } from 'lodash';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { createAnswer, getChoices, updateAnswer, } from '@corti/mission-control-api';
import { Autocomplete, Base, FormControlLabel, Radio, RadioGroup, TextField } from 'lib/cortiUI';
import { useReview, useValidation } from '../context';
import { ChoiceInputLoader } from './ChoiceInputLoader';
export const ChoiceInput = ({ question_id, answer_type, template_id, }) => {
    const { t } = useTranslation('caseView', { keyPrefix: 'manualAssessment.question' });
    const { isReviewLoading, createNewReview, reviewID } = useReview();
    const { addAnsweredQuestion, addLoadingAnswer, removeLoadingAnswer } = useValidation();
    const [choices, setChoices] = React.useState([]);
    const [answerID, setAnswerID] = React.useState('');
    const [comment, setComment] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [isAnswerLoading, setIsAnswerLoading] = React.useState(false);
    const [value, setValue] = React.useState(null);
    React.useEffect(() => {
        if (template_id && question_id) {
            void fetchChoices();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template_id, question_id]);
    async function fetchChoices() {
        setIsLoading(true);
        try {
            const response = await getChoices(template_id, question_id);
            setChoices(response);
        }
        catch (error) {
            console.error('Failed to fetch answer', error);
        }
        finally {
            setIsLoading(false);
        }
    }
    async function getReviewID() {
        if (reviewID)
            return reviewID;
        try {
            return await createNewReview();
        }
        catch (error) {
            console.error('Failed to create review', error);
            return '';
        }
    }
    async function handleAnswerSaving(input) {
        const reviewID = await getReviewID();
        setIsAnswerLoading(true);
        addLoadingAnswer(question_id);
        if (answerID) {
            await updateAnswer(reviewID, answerID, input);
        }
        else {
            const { id } = await createAnswer(reviewID, question_id, input);
            setAnswerID(id);
            addAnsweredQuestion(question_id);
        }
        setIsAnswerLoading(false);
        removeLoadingAnswer(question_id);
    }
    async function handleChoicesChange(value) {
        var _a;
        const answerInput = { choice_ids: [value], text: comment };
        await handleAnswerSaving(answerInput);
        addAnsweredQuestion(question_id);
        setValue((_a = choices.find((it) => it.id === value)) !== null && _a !== void 0 ? _a : null);
    }
    async function handleCommentChange() {
        if (!reviewID && !comment)
            return;
        const answerInput = { choice_ids: value ? [value.id] : [], text: comment };
        await handleAnswerSaving(answerInput);
        removeLoadingAnswer(question_id);
    }
    function renderRadio() {
        return (React.createElement(RadioGroup, { value: value === null || value === void 0 ? void 0 : value.id, onChange: (_, value) => handleChoicesChange(value) }, choices.map((it) => (React.createElement(FormControlLabel, { disabled: isAnswerLoading || isReviewLoading, key: it.id, label: it.text, value: it.id, control: React.createElement(Radio, { color: "primary" }), sx: { m: 2 } })))));
    }
    function renderDropdown() {
        return (React.createElement(Autocomplete, { size: "small", options: choices, disabled: !choices || isLoading || isAnswerLoading, getOptionLabel: (option) => option.text, getOptionKey: (option) => option.id, isOptionEqualToValue: (o, v) => isEqual(o, v), value: value, onChange: (_, value) => {
                if (value) {
                    setValue(value);
                }
            }, renderInput: (params) => React.createElement(TextField, Object.assign({}, params)), clearIcon: null, sx: { minWidth: 220 } }));
    }
    function renderInput() {
        switch (answer_type) {
            case 'DROPDOWN':
                return renderDropdown();
            case 'MULTIPLE_CHOICE':
                return renderRadio();
        }
    }
    if (isLoading) {
        return React.createElement(ChoiceInputLoader, { answer_type: answer_type });
    }
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 5 },
        renderInput(),
        React.createElement(TextField, { value: comment, onChange: ({ target }) => setComment(target.value), label: t('comment', 'Comment'), onFocus: () => addLoadingAnswer(question_id), onBlur: handleCommentChange, disabled: isAnswerLoading || isReviewLoading })));
};
