import React from 'react';
import { isAutomaticReview } from '@corti/mission-control-api';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI';
import { Questions } from './Questions';
import { Score } from './Score';
import { Summary } from './Summary';
export const ActiveReview = ({ selectedReview, selectedReviewTemplate, }) => {
    const theme = useTheme();
    if (['FAILED', 'NONE', 'PENDING_TRANSCRIPT', 'PENDING_INTERACTION', 'SKIPPED'].includes(selectedReview.status))
        return null;
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 6, className: css({ overflow: 'overlay' }, getScrollerCss({ theme: theme })) },
        selectedReviewTemplate.scoring_enabled && (React.createElement(Score, { review: selectedReview, template: selectedReviewTemplate })),
        isAutomaticReview(selectedReview.created_by) && React.createElement(Summary, { reviewID: selectedReview.id }),
        React.createElement(Questions, { template: selectedReviewTemplate, reviewID: selectedReview.id })));
};
