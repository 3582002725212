import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from '@corti/i18n';
import { deleteTemplate } from '@corti/mission-control-api';
import { coreStore } from 'browser/stores';
import { Base, Button, Modal, ModalContent, ModalHeader, Typography } from 'lib/cortiUI';
import { getBaseUrl } from 'lib/router';
export const DiscardAction = ({ disabled = false, templateID }) => {
    const { t } = useTranslation('template', { keyPrefix: 'builder.configuration.actions' });
    const history = useHistory();
    const [isOpen, setIsOpen] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    async function handleDiscard() {
        try {
            setIsDeleting(true);
            await deleteTemplate(templateID);
            history.push(getBaseUrl(history.location.pathname));
            coreStore.notifications.showNotification({
                message: t('discard.success', 'Template is deleted'),
            });
        }
        catch (error) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('discard.error', 'Failed to discard template'),
            });
            console.error('[Template Builder]: Discard template draft', error);
        }
        finally {
            setIsDeleting(false);
            setIsOpen(false);
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "outlined", size: "small", disabled: disabled, onClick: () => setIsOpen(true) }, t('discardBtn', 'Discard')),
        React.createElement(Modal, { open: isOpen, onClose: () => setIsOpen(false) },
            React.createElement(React.Fragment, null,
                React.createElement(ModalHeader, { title: t('discardModal.title', 'Discard template') }),
                React.createElement(ModalContent, { display: "flex", flexDirection: "column", gap: 5 },
                    React.createElement(Typography, null, t('discardModal.description', 'All data for this template will be lost and the template will be deleted. This action cannot be undone.')),
                    React.createElement(Base, { display: "flex", justifyContent: "right", gap: 3 },
                        React.createElement(Button, { variant: "text", onClick: () => setIsOpen(false), disabled: isDeleting }, t('discardModal.save', 'Save as draft')),
                        React.createElement(Button, { onClick: handleDiscard, color: "error", disabled: isDeleting }, t('discardModal.delete', 'Discard'))))))));
};
