import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Button } from 'lib/cortiUI';
import { ReviewItem } from './ReviewItem';
export const Reviews = ({ reviews, selectedReview, onSelectReview }) => {
    const { t } = useTranslation('caseView', { keyPrefix: 'assessment.review' });
    const [isExpanded, setIsExpanded] = React.useState(false);
    return (React.createElement(Base, null,
        isExpanded ? (reviews.map((review) => (React.createElement(ReviewItem, Object.assign({ key: review.id }, review, { selected: selectedReview.id === review.id, onClick: () => onSelectReview(review), showScore: !['FAILED', 'NONE', 'PENDING_TRANSCRIPT', 'PENDING_INTERACTION', 'SKIPPED'].includes(review.status) }))))) : (React.createElement(ReviewItem, Object.assign({}, selectedReview, { selected: true }))),
        reviews.length > 1 && (React.createElement(Button, { onClick: () => setIsExpanded(!isExpanded), size: "small", variant: "text", sx: { ml: 1 } }, isExpanded
            ? t('hide', {
                count: reviews.length - 1,
                defaultValue_one: `Hide {{count}} other assessment`,
                defaultValue_other: `Hide {{count}} other assessments`,
            })
            : t('show', {
                count: reviews.length - 1,
                defaultValue_one: `Show {{count}} other assessment`,
                defaultValue_other: `Show {{count}} other assessments`,
            })))));
};
