import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from '@corti/i18n';
import { duplicateQuestion, updateQuestion, } from '@corti/mission-control-api';
import { useTheme } from '@corti/theme';
import { coreStore } from 'browser/stores';
import { Base, Card, CardActions, CardContent, Separator } from 'lib/cortiUI';
import { useTemplate } from '../context';
import { Actions, AnswerTypeInput, Choices, RequiredInput, TitleInput } from './components';
export const Question = ({ question, isActive = false, onClick, onDelete, onCopy, }) => {
    const { id, text, answer_type, required } = question;
    const { palette } = useTheme();
    const { t } = useTranslation('templateBuilder', { keyPrefix: 'questions' });
    const { templateID } = useTemplate();
    const INITIAL_FORM_VALUES = { text, answer_type, required };
    const oldQuestionValuesRef = React.useRef(INITIAL_FORM_VALUES);
    const [isLoading, setIsLoading] = React.useState(false);
    const { handleSubmit: submitForm, control, watch, reset, } = useForm({
        defaultValues: INITIAL_FORM_VALUES,
        reValidateMode: 'onChange',
        shouldFocusError: true,
    });
    const answerType = watch('answer_type');
    async function handleCopy() {
        setIsLoading(true);
        try {
            const { id: newQuestionID } = await duplicateQuestion(templateID, id);
            onCopy(Object.assign(Object.assign(Object.assign({}, question), oldQuestionValuesRef.current), { id: newQuestionID }));
            coreStore.notifications.showNotification({
                message: t('actions.copy', 'Duplicated!'),
                type: 'success',
            });
        }
        catch (error) {
            coreStore.notifications.showNotification({
                message: t('actions.copyError', 'Failed to duplicate the question!'),
                type: 'error',
            });
            console.error('[Template Builder]: Duplicate question', error);
        }
        finally {
            setIsLoading(false);
        }
    }
    const handleSubmit = submitForm(async ({ answer_type, required, text }) => {
        const input = {
            answer_type,
            required,
            question: text,
        };
        setIsLoading(true);
        try {
            await updateQuestion(templateID, id, input);
            oldQuestionValuesRef.current = Object.assign(Object.assign({}, question), { answer_type, required, text });
        }
        catch (error) {
            coreStore.notifications.showNotification({
                message: t('actions.updateQuestionError', 'Failed to update question!'),
                type: 'info',
            });
            console.error(`[Template Builder]: Failed to update question with ID ${id}`, error);
            reset(oldQuestionValuesRef.current);
        }
        finally {
            setIsLoading(false);
        }
    });
    return (React.createElement(Card, { p: 0, style: isActive
            ? {
                borderLeft: `5px solid ${palette.primary.main}`,
            }
            : undefined, onClick: () => onClick(id), role: "presentation" },
        React.createElement(CardContent, { sx: { px: 6 } },
            React.createElement(Base, { my: 7, display: "flex", justifyContent: "space-between" },
                React.createElement(TitleInput, { control: control, onSaveValue: handleSubmit }),
                React.createElement(AnswerTypeInput, { control: control, onSaveValue: handleSubmit })),
            (answerType === 'DROPDOWN' || answerType === 'MULTIPLE_CHOICE') && React.createElement(Choices, null)),
        React.createElement(Separator, { horizontal: true }),
        React.createElement(CardActions, { sx: { py: 6, px: 6, display: 'flex', justifyContent: 'space-between', width: '100%' } },
            React.createElement(Base, { display: "flex", justifyContent: "flex-start" },
                React.createElement(RequiredInput, { control: control, onSaveValue: handleSubmit })),
            React.createElement(Actions, { onCopy: handleCopy, onRemove: onDelete, loading: isLoading }))));
};
