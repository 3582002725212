var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { InputAdornment, TextField } from 'lib/cortiUI';
export const ThresholdInput = (_a) => {
    var { value, error, disabled = false } = _a, props = __rest(_a, ["value", "error", "disabled"]);
    return (React.createElement(TextField, Object.assign({ value: value.toString(), type: "number", error: !!error, helperText: error, InputProps: {
            startAdornment: React.createElement(InputAdornment, { position: "start" }, disabled ? '<' : '≥'),
            endAdornment: React.createElement(InputAdornment, { position: "start" }, "%"),
        }, inputProps: { min: 1, max: 100 }, fullWidth: true, disabled: disabled }, props)));
};
