import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { getDefaultTemplate, getTemplatesList } from '@corti/mission-control-api';
import { Base, ChipV2, ListItemText, Menu, MenuItem, Skeleton, Typography } from 'lib/cortiUI';
import { DiscardDialog } from '../components';
import { useReview } from '../context';
export const Templates = () => {
    var _a;
    const { templateID, setTemplateID, removeCurrentReview, reviewID } = useReview();
    const { t } = useTranslation('caseView', { keyPrefix: 'manualAssessment.header.templates' });
    const [isLoading, setIsLoading] = React.useState(false);
    const [templates, setTemplates] = React.useState([]);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const [isDefaultTemplateLoading, setIsDefaultTemplateLoading] = React.useState(false);
    const defaultTemplateIdRef = React.useRef();
    const temporarySelectedTemplateIdRef = React.useRef();
    const selectedTemplate = templates.find((t) => t.id === templateID);
    React.useEffect(() => {
        void fetchTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        if (templates.length)
            void fetchDefaultTemplate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templates]);
    async function fetchTemplates() {
        setIsLoading(true);
        try {
            const res = await getTemplatesList();
            const publishedTemplates = res.filter((t) => t.published && t.status === 'ACTIVE');
            if (publishedTemplates.length) {
                setTemplates(publishedTemplates);
            }
        }
        catch (e) {
            console.error('[Case view]: Could not fetch templates', e);
        }
        finally {
            setIsLoading(false);
        }
    }
    //FIXME: `default` property should be returned with Template
    async function fetchDefaultTemplate() {
        setIsDefaultTemplateLoading(true);
        try {
            const res = await getDefaultTemplate();
            if (templateID !== res.id) {
                setTemplateID(res.id);
                defaultTemplateIdRef.current = res.id;
            }
        }
        catch (e) {
            console.error('[Case view]: Could not fetch default template', e);
            //Fallback to first template
            if (templates.length)
                setTemplateID(templates[0].id);
        }
        finally {
            setIsDefaultTemplateLoading(false);
        }
    }
    function handleTemplateChange(template) {
        if (template.id === templateID)
            setMenuAnchorEl(null);
        if (!reviewID) {
            setTemplateID(template.id);
        }
        else {
            temporarySelectedTemplateIdRef.current = template.id;
            setIsDialogOpen(true);
        }
        setMenuAnchorEl(null);
    }
    function handleDiscard() {
        removeCurrentReview();
        if (temporarySelectedTemplateIdRef.current)
            setTemplateID(temporarySelectedTemplateIdRef.current);
        setIsDialogOpen(false);
    }
    return (React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", gap: 3 },
        React.createElement(Typography, { variant: "body2" }, t('template', 'Template')),
        !isLoading && !isDefaultTemplateLoading ? (React.createElement(React.Fragment, null,
            React.createElement(ChipV2, { onClick: templates.length <= 1
                    ? undefined
                    : ({ currentTarget }) => {
                        setMenuAnchorEl(currentTarget);
                    }, label: React.createElement(Base, { display: "grid", gridTemplateColumns: "auto auto", gap: 3 },
                    React.createElement(Typography, { variant: "caption", noWrap: true }, (_a = selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.name) !== null && _a !== void 0 ? _a : ''),
                    templates.length > 1 ? (Boolean(menuAnchorEl) ? (React.createElement(KeyboardArrowUpRoundedIcon, { fontSize: "small" })) : (React.createElement(KeyboardArrowDownRoundedIcon, { fontSize: "small" }))) : null) }),
            templates.length > 1 && (React.createElement(React.Fragment, null,
                React.createElement(Menu, { open: Boolean(menuAnchorEl), onClose: () => setMenuAnchorEl(null), anchorEl: menuAnchorEl, disablePortal: true }, templates.map((template) => (React.createElement(MenuItem, { onClick: () => handleTemplateChange(template), selected: template.id === (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.id), gap: 9 },
                    React.createElement(ListItemText, null, template.name),
                    defaultTemplateIdRef.current === template.id && (React.createElement(ChipV2, { label: t('default', 'default') })))))),
                React.createElement(DiscardDialog, { isOpen: isDialogOpen, onClose: () => setIsDialogOpen(false), onDiscard: handleDiscard }))))) : (React.createElement(Skeleton, { variant: "text", width: 50 }))));
};
