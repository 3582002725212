var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { InfoRounded } from '@mui/icons-material';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { isEqual } from 'lodash';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from '@corti/i18n';
import { updateChoice } from '@corti/mission-control-api';
import { coreStore } from 'browser/stores';
import { Base, IconButton, TextField, Tooltip } from 'lib/cortiUI';
import { useQuestion, useTemplate } from '../../context';
export const Choice = (_a) => {
    var _b = _a.choice, { id } = _b, choiceData = __rest(_b, ["id"]), { disabled = false, onRemove, label } = _a;
    const { t } = useTranslation('templateBuilder', { keyPrefix: 'questions.choices' });
    const { templateID, isScoringEnabled } = useTemplate();
    const questionID = useQuestion();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const oldChoiceValuesRef = React.useRef(choiceData);
    const { control, handleSubmit: onSubmit, reset, } = useForm({
        defaultValues: choiceData,
    });
    const updateChoiceData = async (newChoiceValues) => {
        if (isEqual(newChoiceValues, oldChoiceValuesRef.current)) {
            return;
        }
        setIsSubmitting(true);
        try {
            await updateChoice(templateID, questionID, id, newChoiceValues);
            oldChoiceValuesRef.current = newChoiceValues;
        }
        catch (error) {
            reset(oldChoiceValuesRef.current);
            coreStore.notifications.showNotification({
                message: t('actions.updateError', 'An error occurred while updating choice'),
                type: 'info',
            });
            console.error('[Template Builder]: Failed to update choice', error);
        }
        finally {
            setIsSubmitting(false);
        }
    };
    const handleSubmit = onSubmit(updateChoiceData);
    return (React.createElement(Base, { display: "flex", alignItems: "center", width: "100%", gap: 6 },
        React.createElement(Controller, { name: "text", control: control, rules: { required: t('validation.requiredTitle', 'Choice text is required') }, render: ({ field, fieldState }) => {
                var _a;
                return (React.createElement(TextField, Object.assign({ sx: { flex: 1 }, disabled: disabled || isSubmitting || choiceData.not_applicable, error: Boolean(fieldState.error), helperText: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, label: label }, field, { onBlur: handleSubmit, multiline: true })));
            } }),
        React.createElement(Base, { display: "flex", alignItems: "center", justifyContent: isScoringEnabled ? 'space-between' : 'flex-end', flex: 1, gap: 6 },
            isScoringEnabled && (React.createElement(Controller, { name: "score", control: control, render: (_a) => {
                    var _b = _a.field, { value, onChange } = _b, fieldData = __rest(_b, ["value", "onChange"]);
                    return (React.createElement(TextField, Object.assign({ sx: { flex: 1 }, disabled: disabled || isSubmitting || choiceData.not_applicable, type: choiceData.not_applicable ? 'text' : 'number', label: t('validation.scoreLabel', 'Points') }, fieldData, { value: choiceData.not_applicable ? '-' : value, onChange: (e) => onChange(parseInt(e.target.value, 10)), onBlur: () => {
                            if (typeof value !== 'number') {
                                onChange(0);
                            }
                            void handleSubmit();
                        } })));
                } })),
            React.createElement(Base, { display: "flex", alignItems: "center", flex: 3, justifyContent: choiceData.not_applicable ? 'space-between' : 'flex-end' },
                choiceData.not_applicable && (React.createElement(Tooltip, { title: t('notApplicableTooltip', 'A ‘Not Applicable’ answer option allow the reviewer to mark the question as not applicable to the review. Such questions do not count towards the total Assessment score.') },
                    React.createElement(InfoRounded, { color: "action", fontSize: "small" }))),
                React.createElement(Tooltip, { title: t('actions.remove', 'Remove choice') },
                    React.createElement(Base, null,
                        React.createElement(IconButton, { disabled: disabled || isSubmitting, icon: React.createElement(RemoveRoundedIcon, { color: "action", fontSize: "small" }), onClick: onRemove })))))));
};
