import React from 'react';
import { useTranslation } from '@corti/i18n';
import { getQuestions } from '@corti/mission-control-api';
import { coreStore } from 'browser/stores';
import { Base } from 'lib/cortiUI';
import { useParameters, useTemplate } from '../../context';
import { QuestionCard } from './QuestionCard';
import { QuestionsListLoader } from './QuestionsListLoader';
export const QuestionsList = () => {
    const { t } = useTranslation('questionsList', { keyPrefix: 'questionsList' });
    const { filterRequestParams } = useParameters();
    const { template, loading: isTemplateLoading } = useTemplate();
    const [questions, setQuestions] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        void loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterRequestParams, template]);
    const loadData = async () => {
        if (!template) {
            console.warn('[Mission Control] Template is not defined, questions data is not fetched.');
            return;
        }
        setIsLoading(true);
        try {
            const questions = await getQuestions(template.id);
            setQuestions(questions);
        }
        catch (_a) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('fetchQuestionsError', 'Error fetching questions metrics'),
            });
        }
        finally {
            setIsLoading(false);
        }
    };
    if (isLoading || isTemplateLoading) {
        return React.createElement(QuestionsListLoader, null);
    }
    return (React.createElement(Base, { display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 6, width: "100%" }, questions.map((question) => (React.createElement(QuestionCard, { key: question.id, question: question, template: template })))));
};
