import React from 'react';
import { useTranslation } from '@corti/i18n';
import { getReviewsByCall, getTemplateById, } from '@corti/mission-control-api';
import { Base, ChipV2, Typography } from 'lib/cortiUI';
import { SectionWrapper } from '../../components';
import { useInteraction } from '../../context';
import { ActiveReview } from './ActiveReview';
import { AssessmentLoading } from './AssessmentLoading';
import { Header } from './Header';
import { NoAssessment } from './NoAssessment';
import { Reviews } from './Reviews';
export const Assessment = () => {
    const { interaction } = useInteraction();
    const { t } = useTranslation('caseView', { keyPrefix: 'assessment.reviews' });
    const [selectedReview, setSelectedReview] = React.useState();
    const [selectedReviewTemplate, setSelectedReviewTemplate] = React.useState();
    const [reviews, setReviews] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isTemplateLoading, setIsTemplateLoading] = React.useState(false);
    React.useEffect(() => {
        void fetchReviews();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interaction === null || interaction === void 0 ? void 0 : interaction.call_id]);
    async function fetchReviews() {
        var _a;
        setIsLoading(true);
        try {
            if (!interaction)
                return;
            const res = await getReviewsByCall(interaction.call_id);
            const modifiedRes = res.map((review) => (Object.assign({}, review)));
            setReviews(modifiedRes);
            if (!!modifiedRes.length) {
                const selectedReview = (_a = modifiedRes.find((review) => review.latest)) !== null && _a !== void 0 ? _a : modifiedRes[0];
                setSelectedReview(selectedReview);
                void fetchTemplate(selectedReview.template_id);
            }
        }
        catch (e) {
            console.error('Could not fetch a review', e);
        }
        finally {
            setIsLoading(false);
        }
    }
    async function fetchTemplate(templateID) {
        if ((selectedReviewTemplate === null || selectedReviewTemplate === void 0 ? void 0 : selectedReviewTemplate.id) === templateID)
            return;
        setIsTemplateLoading(true);
        try {
            const response = await getTemplateById(templateID);
            setSelectedReviewTemplate(response);
        }
        catch (error) {
            console.error('failed to fetch template:', error);
        }
        finally {
            setIsTemplateLoading(false);
        }
    }
    if (isLoading)
        return React.createElement(AssessmentLoading, null);
    if (!reviews.length || !selectedReview || !selectedReviewTemplate)
        return React.createElement(NoAssessment, null);
    return (React.createElement(SectionWrapper, { display: "flex", flexDirection: "column", gap: 6, overflow: "hidden" },
        React.createElement(Header, null),
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 6, pt: 2, pr: 2 },
            React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", gap: 3 },
                React.createElement(Typography, { variant: "body2" }, t('template', 'Template')),
                !isTemplateLoading && React.createElement(ChipV2, { label: selectedReviewTemplate.name })),
            React.createElement(Reviews, { reviews: reviews, selectedReview: selectedReview, onSelectReview: (review) => {
                    setSelectedReview(review);
                    void fetchTemplate(review.template_id);
                } })),
        React.createElement(ActiveReview, { selectedReview: selectedReview, selectedReviewTemplate: selectedReviewTemplate })));
};
